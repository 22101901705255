import { useEffect } from "react";
import { useRouter } from "next/router";
import { ApolloProvider } from "@apollo/client";
import { GeistProvider } from "@geist-ui/react";
import { useApollo } from "lib/apollo";
import { setMomentLocale } from "helpers/date";
import * as ga from "lib/ga";

import "styles/index.css";

const MyApp = ({ Component, pageProps }) => {
  const apolloClient = useApollo(pageProps);
  const router = useRouter();

  useEffect(() => {
    setMomentLocale();
  }, []);

  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url);
    };

    router.events.on("routeChangeComplete", (e) => {
      const body = document.body;
      body.style.overflow = "auto";
      handleRouteChange(e);
    });

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <ApolloProvider client={apolloClient}>
      <GeistProvider>
        <Component {...pageProps} />
      </GeistProvider>
    </ApolloProvider>
  );
};

export default MyApp;
