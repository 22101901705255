import moment from "moment";
import "moment/locale/pt";

export const setMomentLocale = () => moment.locale("pt");

export const convertBrToUsDate = (date) =>
  moment(date, "DDMMYYYY").format("YYYY-MM-DD");

export const convertUsToBrDate = (date) =>
  moment(date, "YYYYMMDD").format("DD-MM-YYYY");

export const isValidDate = (date) =>
  date == null || date.length === 10
    ? moment(date, "DD-MM-YYYY").isValid()
    : false;

export const isValidTime = (time) => {
  const [hours, minutes] = time?.split(":");

  if (hours && minutes && time.length === 5) {
    return hours < 24 && minutes <= 59;
  } else {
    return false;
  }
};

export const formatDateToGoogleCalendar = (date) =>
  moment(date).format("YYYYMMDDTHHmmssZZ");

export const isCurrentOrFutureDate = (date) =>
  date == null || date.length === 10
    ? moment(date, "DD-MM-YYYY").isSame(new Date(), "day") ||
      moment(date, "DD-MM-YYYY").isAfter(new Date(), "day")
    : false;

export const birthDateValidate = (date) =>
  date == null || date.length === 10
    ? moment(date, "DD-MM-YYYY").isBefore(new Date())
    : false;

export const fullDateFormat = (date) => moment(date).format("dddd, LL");

export const getCurrentAge = (date) => moment().diff(date, "years", false);

// BRAZILIAN TIMEZONE
export const setValidFormatTime = (time) => `${time}:00-03:00`;

export const setValidDateAndTimeFormat = (date, time) =>
  `${convertBrToUsDate(date)}T${setValidFormatTime(time)}`;
